import { FetchAccountsResponseApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  accessToken: string
}

export const fetchAccounts = async ({ accessToken }: Props) => {
  const apiUrl = `/v1/bank/accounts`

  const { data } = await apiClient.get<FetchAccountsResponseApi>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
  })
  return data
}
