import { mergeClasses } from '@blank/utilities'
import { SidebarWithConfigContext } from 'modules/common/contexts/SidebarContext/SidebarWithConfigContext'
import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import { UserFeedbackButton } from 'modules/home/components/UserFeedbackButton/UserFeedbackButton'
import { PropsWithChildren } from 'react'
import { FeedbackAlert } from '../FeedbackAlert/FeedbackAlert'
import { Header } from '../Header/Header'
import { Sidebar } from '../Sidebar/Sidebar'
import { LayoutEmpty } from './LayoutEmpty'
import LayoutMobileProtected from './LayoutMobileProtected'

interface Props {
  className?: string
}

export const LayoutDashboard = ({
  children,
  className,
}: PropsWithChildren<Props>) => {
  const { shouldDisplayUserFeedbackButton } = useCountryConfig()

  return (
    <SidebarWithConfigContext>
      <div className="hidden lg:block">
        <LayoutEmpty>
          <Header withUser withExtendedSupport />
          <div className="flex flex-row">
            <Sidebar />
            <div
              className={mergeClasses(
                'relative flex min-h-dashboardViewport min-w-0 flex-1',
                className
              )}
            >
              {children}
              <FeedbackAlert />
            </div>
          </div>
        </LayoutEmpty>
        {shouldDisplayUserFeedbackButton && <UserFeedbackButton />}
      </div>
      <div className="block lg:hidden">
        <LayoutMobileProtected />
      </div>
    </SidebarWithConfigContext>
  )
}
