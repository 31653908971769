import { PropsWithChildren } from 'react'
import { FeedbackAlert } from '../FeedbackAlert/FeedbackAlert'
import { Header } from '../Header/Header'
import { LayoutEmpty } from './LayoutEmpty'

interface Props {
  withUser?: boolean
  withExtendedSupport?: boolean
}
const LayoutSimple = ({
  children,
  withUser,
  withExtendedSupport,
}: PropsWithChildren<Props>) => {
  return (
    <LayoutEmpty>
      <Header withUser={withUser} withExtendedSupport={withExtendedSupport} />

      <div className="flex flex-row">
        <div className="relative flex min-h-dashboardViewport min-w-0 flex-1 justify-center">
          {children}
          <FeedbackAlert />
        </div>
      </div>
    </LayoutEmpty>
  )
}

export default LayoutSimple
