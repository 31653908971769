import { EnumBillingServiceId } from '@blank/api'
import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { useCorporateLifecycleQueries } from 'modules/corporate/hooks/useCorporateLifecycleQueries'
import { useCorporateOfferServices } from 'modules/corporate/hooks/useCorporateOfferServices'
import { useCorporateSponsorship } from 'modules/corporate/hooks/useCorporateSponsorship'
import { useAdditionalServices } from 'modules/services/hooks/main/useAdditionalServices'
import { useMemo } from 'react'
import {
  IconBank,
  IconHouse,
  IconInvoice,
  IconServices,
  IconSettings,
} from '../components/_icons/icons'
import { EnumRoutes } from '../routes'
import {
  EnumSidebarMenu,
  EnumSidebarSubMenu,
  EnumSubMenuStatus,
  Menu,
} from '../types/sidebar'
import { useCountryConfig } from './useCountryConfig'
import { useFeatureFlipping } from './useFeatureFlipping'
import { useTranslation } from './useTranslation'

export const useSidebarConfig = () => {
  const { t } = useTranslation('common-components')

  const { additionalServices } = useAdditionalServices()
  const { canAccessSettings } = useFeatureFlipping()
  const { shouldDisplayAverageBalanceDocuments, canDepositCheck } =
    useCountryConfig()

  const {
    data: { isKycComplete },
    isSuccess: isKycLifecycleFetchSuccess,
  } = useCorporateLifecycleQueries()

  const { data: internalAccount } = useInternalAccount()

  const { isEligibleForSponsorship } = useCorporateSponsorship()

  const { services } = useCorporateOfferServices()

  const hasCheckFeature = services?.some(
    (service) => service.id === EnumBillingServiceId.CHECK
  )

  const shouldDisplayChecksDeposit = hasCheckFeature || canDepositCheck

  const isKycPending = isKycLifecycleFetchSuccess && !isKycComplete

  const kycRestrictedSubMenuStatus = isKycPending
    ? EnumSubMenuStatus.PENDING
    : EnumSubMenuStatus.ACTIVE

  const menus: Menu[] = useMemo(
    () => [
      {
        id: EnumSidebarMenu.HOME,
        icon: <IconHouse />,
        link: EnumRoutes.HOME,
        title: t(`sidebar.menuLabels.${EnumSidebarMenu.HOME}`),
      },
      {
        id: EnumSidebarMenu.ACCOUNT,
        icon: <IconBank />,
        link: EnumRoutes.ACCOUNTS,
        title: t(`sidebar.menuLabels.${EnumSidebarMenu.ACCOUNT}`),
        subMenus: [
          {
            id: EnumSidebarSubMenu.ACCOUNT_HOME,
            link: EnumRoutes.ACCOUNTS,
            status: EnumSubMenuStatus.ACTIVE,
          },
          ...(internalAccount && !internalAccount.isBlockedInvest
            ? [
                {
                  id: EnumSidebarSubMenu.ACCOUNT_TRANSFER_ORDERS,
                  link: EnumRoutes.ACCOUNT_TRANSFER_ORDERS,
                  status: kycRestrictedSubMenuStatus,
                },
                {
                  id: EnumSidebarSubMenu.ACCOUNT_BENEFICIARIES,
                  link: EnumRoutes.ACCOUNT_BENEFICIARIES,
                  status: kycRestrictedSubMenuStatus,
                },
                {
                  id: EnumSidebarSubMenu.ACCOUNT_CARD,
                  link: EnumRoutes.ACCOUNT_CARD,
                  status: kycRestrictedSubMenuStatus,
                },
                {
                  id: EnumSidebarSubMenu.ACCOUNT_MANDATES,
                  link: EnumRoutes.ACCOUNT_MANDATES,
                  status: kycRestrictedSubMenuStatus,
                },
                ...(shouldDisplayChecksDeposit
                  ? [
                      {
                        id: EnumSidebarSubMenu.ACCOUNT_CHECK,
                        link: EnumRoutes.ACCOUNT_CHECK,
                        status: kycRestrictedSubMenuStatus,
                      },
                    ]
                  : []),
                ...(shouldDisplayAverageBalanceDocuments
                  ? [
                      {
                        id: EnumSidebarSubMenu.ACCOUNT_AVERAGE_BALANCE_DOCUMENTS,
                        link: EnumRoutes.ACCOUNT_AVERAGE_BALANCE_DOCUMENTS,
                        status: kycRestrictedSubMenuStatus,
                      },
                    ]
                  : []),
              ]
            : []),
        ],
      },
      {
        id: EnumSidebarMenu.INVOICING,
        icon: <IconInvoice />,
        link: EnumRoutes.INVOICING_DOCUMENTS,
        title: t(`sidebar.menuLabels.${EnumSidebarMenu.INVOICING}`),
        subMenus: [
          {
            id: EnumSidebarSubMenu.INVOICING_HOME,
            link: EnumRoutes.INVOICING_DOCUMENTS,
            status: EnumSubMenuStatus.ACTIVE,
          },
          {
            id: EnumSidebarSubMenu.INVOICING_CUSTOMERS,
            link: EnumRoutes.INVOICING_CUSTOMERS,
            status: EnumSubMenuStatus.ACTIVE,
          },
          {
            id: EnumSidebarSubMenu.INVOICING_SETTINGS,
            link: EnumRoutes.INVOICING_SETTINGS,
            status: EnumSubMenuStatus.ACTIVE,
          },
        ],
      },
      ...((additionalServices || []).length > 0
        ? [
            {
              id: EnumSidebarMenu.SERVICES,
              icon: <IconServices />,
              link: EnumRoutes.SERVICES,
              title: t(`sidebar.menuLabels.${EnumSidebarMenu.SERVICES}`, {
                count: additionalServices?.length,
              }),
            },
          ]
        : []),

      {
        id: EnumSidebarMenu.SETTINGS,
        icon: <IconSettings />,
        link: EnumRoutes.SETTINGS_SUBSCRIPTION_HOME,
        title: t(`sidebar.menuLabels.${EnumSidebarMenu.SETTINGS}`),
        subMenus: [
          {
            id: EnumSidebarSubMenu.SETTINGS_HOME,
            link: EnumRoutes.SETTINGS_SUBSCRIPTION_HOME,
            status: EnumSubMenuStatus.ACTIVE,
          },
          ...(canAccessSettings
            ? [
                {
                  id: EnumSidebarSubMenu.SETTINGS_PROFILE,
                  link: EnumRoutes.SETTINGS_PROFILE_PERSONAL_INFORMATION,
                  status: EnumSubMenuStatus.ACTIVE,
                },
                {
                  id: EnumSidebarSubMenu.SETTINGS_INSURANCES,
                  link: EnumRoutes.SETTINGS_INSURANCES,
                  status: EnumSubMenuStatus.ACTIVE,
                },
                {
                  id: EnumSidebarSubMenu.SETTINGS_AUTHENTICATION,
                  link: EnumRoutes.SETTINGS_AUTHENTICATION,
                  status: EnumSubMenuStatus.ACTIVE,
                },
              ]
            : []),
          ...(isEligibleForSponsorship
            ? [
                {
                  id: EnumSidebarSubMenu.SETTINGS_SPONSORSHIP,
                  link: EnumRoutes.SETTINGS_SPONSORSHIP,
                  status: EnumSubMenuStatus.ACTIVE,
                },
              ]
            : []),
        ],
      },
    ],
    [
      t,
      internalAccount,
      kycRestrictedSubMenuStatus,
      shouldDisplayChecksDeposit,
      shouldDisplayAverageBalanceDocuments,
      additionalServices,
      canAccessSettings,
      isEligibleForSponsorship,
    ]
  )

  return { menus }
}
